import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCartItems, removeFromCart, removeOneFromCart, clearCart } from '../redux/cartSlice';
import { selectTransactionStatus, markTransactionComplete } from '../redux/transactionStatusSlice';
import { createOrder } from '../redux/orderSlice';
import { updateProductStock } from '../redux/productsSlice';
import './Cart.css';
import PayPalButton from './PayPalButton';
import CheckoutForm from './CheckoutForm';

const Cart = () => {
  const cartItems = useSelector(selectCartItems);
  const transactionCompleted = useSelector(selectTransactionStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    if (transactionCompleted) {
      console.log('Navigating to /confirmation');
      navigate('/confirmation');
    }
  }, [transactionCompleted, navigate]);

  const handleFormSubmit = (details) => {
    setUserDetails(details);
  };

  const handleStockChange = (id, newStock) => {
    console.log(`Updating stock for product ${id} to ${newStock}`);
    dispatch(updateProductStock({ id, stock: newStock }))
      .then((response) => {
        console.log('Update stock response:', response);
      })
      .catch((error) => {
        console.error('Error in stock update:', error);
      });
  };

  const handleSuccess = async (details) => {
    console.log('Payment success callback called: ', details);

    const orderData = {
      email: userDetails.email,
      items: cartItems,
      totalAmount: calculateTotalAmount(),
      address: userDetails.address,
      create_time: new Date().toISOString(),
    };

    console.log('Order data to be sent:', orderData);

    try {
      const result = await dispatch(createOrder(orderData)).unwrap();
      console.log('Order created successfully:', result);

      // Update stock for each product
      cartItems.forEach((item) => handleStockChange(item._id, item.stock - item.quantity));

      dispatch(markTransactionComplete());
      dispatch(clearCart());
      console.log('Transaction completed set to true');
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const calculateTotalAmount = () => {
    return cartItems.reduce((total, item) => {
      const price = parseFloat(item.price);
      const quantity = parseInt(item.quantity, 10);

      if (isNaN(price) || isNaN(quantity)) {
        console.error('Invalid price or quantity', item);
        return total;
      }

      return total + (price * quantity);
    }, 0).toFixed(2);
  };

  return (
    <div className="cart">
      {cartItems.length === 0 ? (
        <p>Votre panier est vide.</p>
      ) : (
        <div className="cart-items">
          {cartItems.map((item) => (
            <div key={item._id + item.size} className="cart-item">
              <div className="cart-item-image-container">
                <img src={item.image} alt={item.name} className="cart-item-image" />
              </div>
              <div className="cart-item-details">
                <h3>{item.name}</h3>
                <p>Size: {item.size}</p>
                <p>Quantity: {item.quantity}</p>
                <p>Price: {item.price}</p>
                <div className="cart-item-buttons">
                  <button className="btn-remove-one" onClick={() => dispatch(removeOneFromCart({ id: item._id, size: item.size }))}>
                    Remove one
                  </button>
                  <button className="btn-remove" onClick={() => dispatch(removeFromCart(item._id))}>
                    Remove from cart
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button className="btn-clear-cart" onClick={() => dispatch(clearCart())}>Clear Cart</button>
          <div className="cart-total">
            <h2>Total: {calculateTotalAmount()}€</h2>
          </div>
          {userDetails ? (
            <PayPalButton amount={calculateTotalAmount()} onSuccess={handleSuccess} />
          ) : (
            <CheckoutForm onSubmit={handleFormSubmit} />
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;
