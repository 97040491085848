// About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>MONSIEUR BASSIN</h1>
      <p>
        « Amis depuis les bancs de l'école et amoureux de notre belle région, c'est tout naturellement que nous nous sommes associés pour créer un concept multimarque destiné aux hommes du Bassin.
      </p>
      <p>
        Vous cherchez une tenue pour aller travailler ? Pour profiter d’un moment entre amis au marché du Cap Ferret ? Pour vous promener dans la rue piétonne d’Andernos ou encore déguster des huîtres au port ostréicole d’Arès ? Vous trouverez chez Monsieur Bassin une sélection de prêt à porter et d’accessoires qui saura correspondre à tous les budgets et tous les styles.
      </p>
      <p>
        N’hésitez plus, venez vite découvrir votre nouvelle boutique à l’ambiance conviviale et chaleureuse ! »
      </p>
      <p>
        Manolie et Frédéric – Monsieur Bassin
      </p>
    </div>
  );
};

export default About;
