/* src/components/Home.js */
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import beachImage from '../assets/beach.jpg'; // Ensure this path is correct
import hauts from '../assets/chemise_avant.png';
import tshirt from '../assets/tshirt_blanc_derriere.png';
import maillot from '../assets/maillot_cyan_avant.png';
import accessoire from '../assets/bob_bleu.png';
import bassin_writing from '../assets/monsieur-bassin-writing-blue.png';
import white_emblem from '../assets/monsieur-bassin-white-emblem4.png';


const Home = () => {
  const categoriesRef = useRef(null);
    const categories = [
        { id: 1, title: 'Hauts', imageUrl: hauts },
        { id: 2, title: 'T-Shirts', imageUrl: tshirt },
        { id: 3, title: 'Bas', imageUrl: maillot },
        { id: 4, title: 'Accessoires', imageUrl: accessoire },
    ];

  // Handle scroll for header vanish
  useEffect(() => {
    let lastScrollTop = 0;
    const header = document.querySelector('header');

    window.addEventListener('scroll', () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        // Downscroll
        header.classList.add('hidden');
      } else {
        // Upscroll
        header.classList.remove('hidden');
      }
      lastScrollTop = scrollTop;
    });

    return () => {
      window.removeEventListener('scroll', null);
    };
  }, []);


  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const containers = categoriesRef.current.querySelectorAll('.category-card-container');
    containers.forEach(container => observer.observe(container));

    return () => observer.disconnect();
  }, []);

  // Fade-in effect
  useEffect(() => {
    const sections = document.querySelectorAll('.home-section');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  // Animate hero image and overlay on scroll
  useEffect(() => {
    const heroImage = document.querySelector('.hero-image');
    const heroOverlay = document.querySelector('.hero-overlay');

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 100) { // Adjust this value as needed
        heroImage.classList.add('animate');
        heroOverlay.classList.add('animate');
      } else {
        heroImage.classList.remove('animate');
        heroOverlay.classList.remove('animate');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="home">
      <div className="hero-section">
        <img src={beachImage} alt="Beach" className="hero-image" />
        <div className="hero-overlay">
          <div className="emblem-container">
          <img src={white_emblem} className="home-emblem" alt={"Monsieur Bassin Emblem"}/>
          </div>
          <div className="emblem-write">
          Collection 2024
          </div>
          {/*<Link to="/products" className="discover-button-link">
            <button className="discover-button">Découvrir</button>
          </Link>*/}
        </div>
      </div>
      <section className="categories-section" id="collections">
        <div className="section-title">
        <h2>Collections</h2>
        </div>
        <div className="categories-cards" ref={categoriesRef}>
          {categories.map((category, index) => (
            <div key={category.id} className="category-card-container">
              <a
                href={`${category.title.toLowerCase().replace(/-/g, '')}`}
                className="category-card"
                style={{ '--animation-delay': `${index * 0.4}s` }}
              >
              <Link to={`${category.title.toLowerCase().replace(/-/g, '')}`}>
                <img src={category.imageUrl} alt={category.title} />
              </Link>
              </a>
              <Link to={`${category.title.toLowerCase().replace(/-/g, '')}`} className="category-card-title">{category.title} &rarr;</Link>
            </div>
          ))}
        </div>
      </section>
        {/* About Section */}
      <section className="about-container2">
        <div className="writing-container">
            <img src={bassin_writing} className="writing" alt={"Monsieur Bassin"} />
        </div>
      <p>
        « Amis depuis les bancs de l'école et amoureux de notre belle région, c'est tout naturellement que nous nous sommes associés pour créer un concept multimarque destiné aux hommes du Bassin.
      </p>
      <p>
        Vous cherchez une tenue pour aller travailler ? Pour profiter d’un moment entre amis au marché du Cap Ferret ? Pour vous promener dans la rue piétonne d’Andernos ou encore déguster des huîtres au port ostréicole d’Arès ? Vous trouverez chez Monsieur Bassin une sélection de prêt à porter et d’accessoires qui saura correspondre à tous les budgets et tous les styles.
      </p>
      <p>
        N’hésitez plus, venez vite découvrir votre nouvelle boutique à l’ambiance conviviale et chaleureuse ! »
      </p>
      <p>
        Notre adresse: Route de Bordeaux, 33740 Arès
      </p>
      <p>
        Manolie et Frédéric – Monsieur Bassin
      </p>
      </section>
    </div>
  );
};

export default Home;
