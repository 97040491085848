import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cartReducer from './cartSlice';
import productsReducer from './productsSlice';
import notificationReducer from './notificationSlice';
import transactionStatusReducer from './transactionStatusSlice';
import orderReducer from './orderSlice';
import clientReducer from './clientSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  cart: cartReducer,
  products: productsReducer,
  notifications: notificationReducer,
  transactionStatus: transactionStatusReducer,
  order: orderReducer,
  client: clientReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);
//persistor.purge()
export { store, persistor };
