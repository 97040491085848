import React from 'react';
import { Link } from 'react-router-dom';
import './Products.css';

const ProductCard = ({ product }) => {
  const secondImage = product.images[1] || product.images[0];

  return (
    <div className="product-card">
      <Link to={`/product/${product._id}`}>
        <div className="product-image-container">
          <img src={product.images[0]} alt={product.name} className="product-image" />
          <img src={secondImage} alt={product.name} className="product-image-hover" />
        </div>
        <div className="product-info">
          <h3 className="product-name">{product.name}</h3>
          <p className="product-price">{product.price}</p>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
