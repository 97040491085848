import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectProducts } from '../redux/productsSlice';
import { addToCart } from '../redux/cartSlice';
import { showNotification } from '../redux/notificationSlice';
import { useNavigate } from 'react-router-dom';
import './ProductDetail.css';

const ProductDetail = () => {
  const { id } = useParams();
  const products = useSelector(selectProducts);
  const product = products.find(p => p._id === id);
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState(product.sizes[0]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [stock, setStock] = useState(product.stock);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
      const getStockForSize = (size) => {
          return product.stock;
      };
      setStock(getStockForSize(selectedSize));
  }, [selectedSize, product]);

  const handleIncreaseQuantity = () => setQuantity(quantity + 1);
  const handleDecreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleAddToCart = () => {
    if (quantity > stock) {
      dispatch(showNotification(`Insufficient stock for ${product.name}`));
      return;
    }

    dispatch(addToCart({
      _id: product._id, // Ensure _id is included here
      name: product.name,
      price: product.price,
      image: product.images[currentImageIndex],
      description: product.description,
      quantity,
      size: selectedSize,
      stock: product.stock
    }));
    dispatch(showNotification(`Added ${product.name} to cart`));
  };

  const handleProceedToPayment = () => {
    if (quantity > stock) {
      dispatch(showNotification(`Insufficient stock for ${product.name}`));
      return;
    }

    dispatch(addToCart({
      ...product,
      quantity,
      size: selectedSize,
      image: product.images[currentImageIndex]
    }));
    navigate('/cart');
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.images.length) % product.images.length);
  };

  return (
    <div className="product-detail">
      <div className="image-column">
        <div className="product-detail-image-container">
          <img src={product.images[currentImageIndex]} alt={product.name} className="product-detail-image" />
        </div>
        {product.images.length > 1 && (
          <div className="image-navigation">
            <button className="image-nav-arrow left" onClick={handlePreviousImage}>
              <i className="fas fa-long-arrow-alt-left"></i>
            </button>
            <div className="image-dots">
              {product.images.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${currentImageIndex === index ? 'active' : ''}`}
                  onClick={() => setCurrentImageIndex(index)}
                ></span>
              ))}
            </div>
            <button className="image-nav-arrow right" onClick={handleNextImage}>
              <i className="fas fa-long-arrow-alt-right"></i>
            </button>
          </div>
        )}
      </div>
      <div className="product-detail-info">
        <h1 className="product-name">{product.name}</h1>
        <p className="product-price">{product.price}</p>
        <div className="size-selector">
          <label htmlFor="size">Taille:</label>
          <div className="size-buttons">
            {product.sizes.map(size => (
              <button 
                key={size} 
                className={`size-button ${selectedSize === size ? 'selected' : ''}`}
                onClick={() => setSelectedSize(size)}
              >
                {size}
              </button>
            ))}
          </div>
        </div>
        <div className="quantity-selector">
          <label htmlFor="quantity">Quantité:</label>
          <div className="quantity-buttons">
            <button onClick={handleDecreaseQuantity} className="quantity-button">-</button>
            <span className="quantity">{quantity}</span>
            <button onClick={handleIncreaseQuantity} className="quantity-button">+</button>
          </div>
        </div>
        <div className="stock-info">
          <p>Stock: {stock}</p>
        </div>
        <button className="add-to-cart" onClick={handleAddToCart} disabled={quantity > stock}>
          <i className="fas fa-shopping-cart"></i> Ajouter au panier
        </button>
        <button className="buy-now" onClick={handleProceedToPayment} disabled={quantity > stock}>
          <i className="fas fa-credit-card"></i> Acheter maintenant
        </button>
        <div className="additional-info">
          <div className="product-composition">
            <h3>Composition</h3>
            <ul>
              {product.composition.map((x, index) => (
                <li key={index}>{x}</li>
              ))}
            </ul>
          </div>
          <div className="product-about">
            <h3>À propos</h3>
            <ul>
              {product.about.map((x, index) => (
                <li key={index}>{x}</li>
              ))}
            </ul>
          </div>
          <div className="engagements">
            <h3>Engagements</h3>
            <div className="engagement-icons">
              {product.engagements.map((icon, index) => (
                <img src={icon} alt="engagement icon" key={index} className="engagement-icon" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
