import React, {useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { selectProducts } from '../redux/productsSlice';
import ProductCard from './ProductCard';
import './Products.css';

const Tshirts = () => {
  const products = useSelector(selectProducts);
  const tshirtProducts = products.filter(product => product.category === 'Tshirt');


  const productsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const containers = productsRef.current.querySelectorAll('.product-card');
    containers.forEach(container => observer.observe(container));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="products" ref={productsRef}>
      {tshirtProducts.length > 0 ? (
        tshirtProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))
      ) : (
        <p>No products found in this category.</p>
      )}
    </div>
  );
};

export default Tshirts;
