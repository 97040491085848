import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { TransactionStatusProvider } from './context/TransactionStatusContext';
import { store, persistor } from './redux/store';
import './assets/TropilineSans.css'; // Import the font CSS
// index.js or App.js
import '@fortawesome/fontawesome-free/css/all.min.css';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <TransactionStatusProvider>
        <App />
      </TransactionStatusProvider>
    </PersistGate>
  </Provider>
);
