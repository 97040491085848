import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, addProduct, updateProductStock, selectProducts } from '../redux/productsSlice';
import { v4 as uuidv4 } from 'uuid';
import './AdminProducts.css';

const AdminProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);

  const [newProduct, setNewProduct] = useState({
    _id: uuidv4(),  // Generate a unique ID for the new product
    name: '',
    price: '',
    images: [],
    category: '',
    description: '',
    sizes: '',
    composition: '',
    about: '',
    engagements: '',
    stock: 0,
  });
  const [imageFiles, setImageFiles] = useState([]);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleAddProduct = () => {
    const productWithImages = {
      ...newProduct,
      images: imageFiles.map(file => URL.createObjectURL(file)),
    };
    dispatch(addProduct(productWithImages));
    setNewProduct({
      _id: uuidv4(),  // Generate a new unique ID for the next product
      name: '',
      price: '',
      images: [],
      category: '',
      description: '',
      sizes: '',
      composition: '',
      about: '',
      engagements: '',
      stock: 0,
    });
    setImageFiles([]);
  };

  const handleRemoveProduct = (id) => {
    // Implement removeProduct function as needed
  };

  const handleStockChange = (id, newStock) => {
    console.log(`Updating stock for product ${id} to ${newStock}`);
    dispatch(updateProductStock({ id: id, stock: newStock })).then((response) => {
      console.log('Update stock response:', response);
    });
  };

  const handleImageChange = (e) => {
    setImageFiles([...e.target.files]);
  };

  return (
    <div className="admin-products">
      <h2>Gestion Produits</h2>
      <div className="admin-product-list">
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product._id} className="admin-product-item">
              <h3>{product.name}</h3>
              <p>Prix: {product.price}</p>
              <div className="stock-management">
                <button onClick={() => handleStockChange(product._id, product.stock - 1)} disabled={product.stock <= 0}>-</button>
                <span>Stock: {product.stock}</span>
                <button onClick={() => handleStockChange(product._id, product.stock + 1)}>+</button>
              </div>
              <button onClick={() => handleRemoveProduct(product._id)}>Remove</button>
            </div>
          ))
        ) : (
          <p>Aucun produit disponible</p>
        )}
      </div>
      <div className="admin-product-form">
        <h3>Ajouter un nouveau produit</h3>
        <input
          type="text"
          placeholder="Nom"
          value={newProduct.name}
          onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Prix"
          value={newProduct.price}
          onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
        />
        <input
          type="text"
          placeholder="Catégorie"
          value={newProduct.category}
          onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
        />
        <input
          type="text"
          placeholder="Description"
          value={newProduct.description}
          onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
        />
        <input
          type="text"
          placeholder="Tailles"
          value={newProduct.sizes}
          onChange={(e) => setNewProduct({ ...newProduct, sizes: e.target.value.split(',') })}
        />
        <input
          type="text"
          placeholder="Composition"
          value={newProduct.composition}
          onChange={(e) => setNewProduct({ ...newProduct, composition: e.target.value.split(',') })}
        />
        <input
          type="text"
          placeholder="À propos"
          value={newProduct.about}
          onChange={(e) => setNewProduct({ ...newProduct, about: e.target.value.split(',') })}
        />
        <input
          type="text"
          placeholder="Engagements"
          value={newProduct.engagements}
          onChange={(e) => setNewProduct({ ...newProduct, engagements: e.target.value.split(',') })}
        />
        <input
          type="number"
          placeholder="Stock"
          value={newProduct.stock}
          onChange={(e) => setNewProduct({ ...newProduct, stock: parseInt(e.target.value, 10) })}
        />
        <input
          type="file"
          multiple
          onChange={handleImageChange}
        />
        <button onClick={handleAddProduct}>Ajouter produit</button>
      </div>
    </div>
  );
};

export default AdminProducts;
