import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import QRCode from 'qrcode';

export const createOrder = createAsyncThunk('order/createOrder', async (orderData) => {
  try {
    console.log('Sending order data:', orderData);
    const response = await axios.post('/api2/checkout', orderData);  // Ensure this is the correct URL
    console.log('Server response:', response.data);  // Log the server response

    const order = response.data.order;
    const validationUrl = `https://monsieurbassin.fr/validate?pickupCode=${order.pickupCode}`;

    // Generate QR code for the validation URL
    const qrCodeDataURL = await QRCode.toDataURL(validationUrl);

    return { order, qrCodeDataURL };  // Ensure the order data and QR code data URL are returned
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;  // Re-throw the error so it can be handled by createAsyncThunk
  }
});

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    order: null,
    qrCodeDataURL: '',
    status: 'idle',
    error: null,
  },
  reducers: {
    resetOrder(state) {
      state.order = null;
      state.qrCodeDataURL = '';
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.fulfilled, (state, action) => {
        console.log('Order created successfully:', action.payload); // Debugging log
        state.order = action.payload.order;
        state.qrCodeDataURL = action.payload.qrCodeDataURL;
        state.status = 'succeeded';
      })
      .addCase(createOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { resetOrder } = orderSlice.actions;
export const selectOrder = (state) => state.order.order;
export const selectQRCode = (state) => state.order.qrCodeDataURL;
export default orderSlice.reducer;
