// Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { showNotification } from '../redux/notificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCartItems } from '../redux/cartSlice';
import logo from '../assets/logo-monsieur-bassin-white.png';

function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);

  const toggleNotification = () => {
    dispatch(showNotification());
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const quantity = parseInt(item.quantity, 10);

      if (isNaN(quantity)) {
        console.error('Invalid price or quantity', item);
        return total;
      }

      return total + quantity;
    }, 0);
  };

 const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <header className="header">
      <div className="menu-icon" onClick={toggleSidebar}>
        <i className="fas fa-bars"></i>
      </div>
      <div className="header-logo">
        <Link to="/">
        <img src={logo} alt="Monsieur Bassin" />
        </Link>
      </div>
      <div className="cart-icon" onClick={toggleNotification}>
          <i className="fas fa-shopping-bag"></i>
          {calculateTotal() > 0 && <span className="cart-count">{calculateTotal()}</span>}
      </div>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <i className="fas fa-times" onClick={closeSidebar}></i>
        </div>
        <nav className="sidebar-nav">
          <Link to="/" className="sidebar-link" onClick={closeSidebar}>Accueil</Link>
          <Link to="/products" className="sidebar-link" onClick={closeSidebar}>La boutique</Link>
          <Link to="/tshirts" className="sidebar-link" onClick={closeSidebar}>T-Shirts</Link>
          <Link to="/hauts" className="sidebar-link" onClick={closeSidebar}>Vestes</Link>
          <Link to="/bas" className="sidebar-link" onClick={closeSidebar}>Bas</Link>
          <Link to="/accessoires" className="sidebar-link" onClick={closeSidebar}>Accessoires</Link>
{/*          <Link to="/about" className="sidebar-link" onClick={closeSidebar}>La marque</Link>*/}
          <Link to="/contact" className="sidebar-link" onClick={closeSidebar}>Contact</Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
