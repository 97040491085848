import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the context
const TransactionStatusContext = createContext();

// Custom hook to use the context
export const useTransactionStatus = () => {
  return useContext(TransactionStatusContext);
};

// Provider component
export const TransactionStatusProvider = ({ children }) => {
  const [transactionCompleted, setTransactionCompleted] = useState(() => {
    // Check localStorage for persisted state
    const savedStatus = localStorage.getItem('transactionCompleted');
    return savedStatus ? JSON.parse(savedStatus) : false;
  });

  const markTransactionComplete = () => {
    setTransactionCompleted(true);
  };

  const resetTransactionStatus = () => {
    setTransactionCompleted(false);
  };

  // Persist state to localStorage on change
  useEffect(() => {
    localStorage.setItem('transactionCompleted', JSON.stringify(transactionCompleted));
  }, [transactionCompleted]);

  return (
    <TransactionStatusContext.Provider value={{ transactionCompleted, markTransactionComplete, resetTransactionStatus }}>
      {children}
    </TransactionStatusContext.Provider>
  );
};
