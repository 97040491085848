import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  address: '',
  deliveryOption: '',
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    saveClientInfo: (state, action) => {
      state.email = action.payload.email;
      state.address = action.payload.address;
      state.deliveryOption = action.payload.deliveryOption;
    },
  },
});

export const { saveClientInfo } = clientSlice.actions;
export const selectClientInfo = (state) => state.client;
export default clientSlice.reducer;
