import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminOrders.css';
import Logout from './Logout';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('/api/admin/orders', { withCredentials: true });
        setOrders(response.data);
      } catch (error) {
        setError('Failed to fetch orders');
      }
    };

    fetchOrders();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="admin-orders">
      <h1>Lise de commandes</h1>
      <Logout />
      {orders.length === 0 ? (
        <p>Aucune commande trouvée.</p>
      ) : (
        <table className="orders-table">
          <thead>
            <tr>
              <th>ID de commande</th>
              <th>Email</th>
              <th>Contenu</th>
              <th>Total</th>
              <th>Adresse</th>
              <th>Statut</th>
              <th>Fait le</th>
              <th>Pickup Code</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order._id}>
                <td>{order.id}</td>
                <td>{order.email}</td>
                <td>
                  <ul>
                    {order.items.map((item, index) => (
                      <li key={index}>
                        {item.name} - Taille: {item.size} - Quantité: {item.quantity} - Prix: {item.price}
                      </li>
                    ))}
                  </ul>
                </td>
                <td>{order.totalAmount}€</td>
                <td>{order.address}</td>
                <td>{order.status}</td>
                <td>{new Date(order.create_time).toLocaleString()}</td>
                <td>{order.pickupCode}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminOrders;
