import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ValidatePage.css';

const ValidateOrder = () => {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pickupCode = params.get('pickupCode');

    const fetchOrder = async () => {
      try {
        const response = await axios.post('/api/validate', { pickupCode }, { withCredentials: true });
        setOrder(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else if (error.response && error.response.status === 400) {
          setError(error.response.data.msg || 'Order not found');
        } else {
          setError('Order not found');
        }
      }
    };

    fetchOrder();
  }, [location.search, navigate]);

  const handleValidateOrder = async () => {
    if (!order || order.status === 'Validé') return;

    try {
      const response = await axios.post('/api/validate-order', { pickupCode: order.pickupCode }, { withCredentials: true });
      setOrder(response.data);
    } catch (error) {
      setError('Validation failed');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="validate-order">
      <h1>Validation de commande</h1>
      <div className="order-details">
        <p><strong>ID de commande:</strong> {order.id}</p>
        <p><strong>Statut:</strong> {order.status}</p>
        <p><strong>Total:</strong> {order.totalAmount}€</p>
        <button onClick={handleValidateOrder} disabled={order.status === 'Validé'}>
          Valider
        </button>
      </div>
    </div>
  );
};

export default ValidateOrder;
