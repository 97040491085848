// src/components/PayPalButton.js
import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalButton = ({ amount, onSuccess }) => {
  return (
    <PayPalScriptProvider options={{ "client-id": "ARIN0n1Jsp_A5U3MBRqRQeykQGm8SAVqaFEmXkSUrzIK3oO8v4d6hPSggfCUIVUw_dE91J4WJvzY1D_5" }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          console.log("Creating order with amount:", amount);
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          console.log("Payment approved, capturing order");
          return actions.order.capture().then((details) => {
            console.log("Order captured, calling onSuccess callback with details:", details);
            onSuccess(details);
          });
        }}
        onError={(err) => {
          console.error("PayPal button error:", err);
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
