import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideNotification } from '../redux/notificationSlice';
import { removeOneFromCart, removeFromCart, addToCart, selectCartItems } from '../redux/cartSlice';
import { useNavigate } from 'react-router-dom';
import './Notification.css';

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { visible } = useSelector(state => state.notifications);
  const cartItems = useSelector(selectCartItems);

  React.useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        dispatch(hideNotification());
      }, 100000); // Adjusted to 10000ms for better visibility
      return () => clearTimeout(timer);
    }
  }, [visible, dispatch]);

  if (!visible) return null;

  const handleProceedToPayment = () => {
    dispatch(hideNotification());
    navigate('/cart');
  };

  const handleRemoveOne = (item) => {
    dispatch(removeOneFromCart({ id: item.id, size: item.size }));
  };

  const handleAddOne = (item) => {
    dispatch(addToCart({ ...item, quantity: 1 }));
  };

  const handleRemoveFromCart = (id) => {
    dispatch(removeFromCart(id));
  };

  const calculateTotalAmount = () => {
    return cartItems.reduce((total, item) => {
      const price = parseFloat(item.price);
      const quantity = parseInt(item.quantity, 10);

      if (isNaN(price) || isNaN(quantity)) {
        console.error('Invalid price or quantity', item);
        return total;
      }

      return total + (price * quantity);
    }, 0).toFixed(2);
  };

  return (
    <>
      <div className={`notification-background ${visible ? 'open' : ''}`} onClick={() => dispatch(hideNotification())}></div>
      <div className={`notification ${visible ? 'open' : ''}`}>
        <button onClick={() => dispatch(hideNotification())} className="close-button">X</button>
        <div className="notification-content">
          <h2>Votre panier</h2>
          {cartItems.length === 0 ? (
            <p>Votre panier est vide</p>
          ) : (
            <ul>
              {cartItems.map((item, index) => (
                <li key={index} className="cart-item">
                  <div className="image-container">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="item-details">
                    <p>{item.name}</p>
                    <p>{item.price}</p>
                    {item.size && (<p>Taille: {item.size}</p>)}
                  </div>
                  <div className="quantity-controls">
                    <button onClick={() => handleRemoveOne(item)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => handleAddOne(item)}>+</button>
                  </div>
                  <button onClick={() => handleRemoveFromCart(item.id)} className="remove-button">🗑️</button>
                </li>
              ))}
            </ul>
          )}
          {calculateTotalAmount() > 0 && (
          <div className="total">
            <p>Total estimé: {calculateTotalAmount()}€</p>
            <button onClick={handleProceedToPayment} className="proceed-button">Procéder au paiement</button>
          </div>)}
        </div>
      </div>
    </>
  );
};

export default Notification;
