import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import CookieConsent, { Cookies } from 'react-cookie-consent';
//import { useCookies } from 'react-cookie';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Products from './components/Products';
import ProductDetail from './components/ProductDetail';
import Notification from './components/Notification';
import Cart from './components/Cart';
import About from './components/About';
import Hauts from './components/Hauts';
import Bas from './components/Bas';
import Tshirts from './components/Tshirts';
import Accessoires from './components/Accessoires';
import ConfirmationPage from './components/ConfirmationPage';
import Contact from './components/Contact';
import Login from './components/Login';
import Validate from './components/ValidatePage';
import AdminOrders from './components/AdminOrders';
import ProtectedRoute from './components/ProtectedRoute';
import AdminProducts from './components/AdminProducts';

const App = () => {
//  const [cookies, setCookie, removeCookie] = useCookies(['myAwesomeCookie']);
  const handleLogin = () => {
    localStorage.setItem('isAuthenticated', 'true');
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Header />
            <Notification />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path="/confirmation" element={<ConfirmationPage />} />
            <Route path="/products" element={<Products />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/hauts" element={<Hauts />} />
            <Route path="/tshirts" element={<Tshirts />} />
            <Route path="/bas" element={<Bas />} />
            <Route path="/accessoires" element={<Accessoires />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route
                path="/validate"
                element={
                    <ProtectedRoute>
                        <Validate />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/admin/orders"
                element={
                    <ProtectedRoute>
                        <AdminOrders />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/admin/products"
                element={
                    <ProtectedRoute>
                        <AdminProducts />
                    </ProtectedRoute>
                }
            />
            <Route path="*" element={<h1>Page not found</h1>} /> {/* Catch-all route */}
          </Routes>
          <Footer />
          <CookieConsent
            location="bottom"
            buttonText="J'accepte"
            declineButtonText="Je refuse"
            cookieName="myAwesomeCookieConsent"
            style={{ background: "#004c91" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            declineButtonStyle={{ color: "#fff", background: "#6c757d", fontSize: "13px" }}
            enableDeclineButton
            onAccept={() => {
              // Handle cookie consent acceptance
              Cookies.set('myAwesomeCookie', 'accepted', { expires: 365 });
            }}
            onDecline={() => {
              // Handle cookie consent rejection
              Cookies.remove('myAwesomeCookie');
            }}
          >
            Ce site internet utilise des cookies pour améliorer l'expérience utilisateur.{" "}
            {/* <a href="/cookie-policy" style={{ color: "#fff", textDecoration: "underline" }}>En savoir plus</a>*/}
          </CookieConsent>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
