import { createSlice } from '@reduxjs/toolkit';

const transactionStatusSlice = createSlice({
  name: 'transactionStatus',
  initialState: false,
  reducers: {
    markTransactionComplete(state) {
      return true;
    },
    resetTransactionStatus() {
      return false;
    },
  },
});

export const { markTransactionComplete, resetTransactionStatus } = transactionStatusSlice.actions;
export const selectTransactionStatus = (state) => state.transactionStatus;
export default transactionStatusSlice.reducer;
