import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { _id, name, price, image, description, quantity, size, stock } = action.payload;
      const existingProduct = state.items.find(item => item._id === _id && item.size === size);

      if (existingProduct) {
        existingProduct.quantity += quantity;
      } else {
        state.items.push({ _id, name, price, image, description, quantity, size, stock });
      }
    },
    removeOneFromCart: (state, action) => {
      const { id, size } = action.payload;
      const existingProduct = state.items.find(item => item._id === id && item.size === size);

      if (existingProduct) {
        if (existingProduct.quantity > 1) {
          existingProduct.quantity -= 1;
        } else {
          state.items = state.items.filter(item => !(item._id === id && item.size === size));
        }
      }
    },
    removeFromCart: (state, action) => {
      const index = state.items.findIndex(item => item._id === action.payload);
      if (index !== -1) {
        state.items.splice(index, 1);
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const { addToCart, removeOneFromCart, removeFromCart, clearCart } = cartSlice.actions;
export const selectCartItems = (state) => state.cart.items;
export default cartSlice.reducer;
