import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProducts, fetchProducts } from '../redux/productsSlice';
import ProductCard from './ProductCard';
import './Products.css';

const Products = () => {
  const products = useSelector(selectProducts);
  const productsRef = useRef(null);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const containers = productsRef.current.querySelectorAll('.product-card');
    containers.forEach(container => observer.observe(container));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="products" ref={productsRef}>
      {products.map(product => (
        <ProductCard key={product._id} product={product} />
      ))}
    </div>
  );
};

export default Products;
