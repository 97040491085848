import React, { useEffect } from 'react';
import './ConfirmationPage.css'; // Import the CSS file for styling
import logo from '../assets/logo-monsieur-bassin.png'; // Ensure you have a logo file in your assets folder
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectTransactionStatus, resetTransactionStatus } from '../redux/transactionStatusSlice';
import { selectOrder, resetOrder, selectQRCode } from '../redux/orderSlice';

const ConfirmationPage = () => {
  const transactionCompleted = useSelector(selectTransactionStatus);
  const order = useSelector(selectOrder);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const qrCodeDataURL = useSelector(selectQRCode);

  useEffect(() => {
    if (!transactionCompleted) {
      console.log('Transaction not completed, navigating to /');
      navigate('/');
    } else {
      console.log('ConfirmationPage useEffect, transactionCompleted: true');
    }

    console.log('Order data in ConfirmationPage:', order);

    return () => {
      console.log('ConfirmationPage unmounted, resetting transactionCompleted');
      dispatch(resetTransactionStatus());
      dispatch(resetOrder());
    };
  }, [transactionCompleted, navigate, dispatch, order]); // Removed 'order' from the dependency array

  const handleGoHome = () => {
    navigate('/');
  };

  console.log('Rendering ConfirmationPage', order);

  if (!order || !order.items) {
    return <div>Loading...</div>; // Display loading state if order is not available
  }

  return (
    <div className="confirmation-container">
      <img src={logo} alt="Logo" className="confirmation-logo" />
      <h1>Paiement réalisé avec succès!</h1>
      <p>Monsieur Bassin vous remercie pour votre achat. Un email de confirmation de commande vous a été envoyé à <strong>{order.email}</strong>.</p>
      <div className="confirmation-content">
        {order.pickupCode && <p>Code Pickup: {order.pickupCode}</p>}
        {qrCodeDataURL && <img src={qrCodeDataURL} alt="QR Code" className="confirmation-qrcode" />}
        <button onClick={handleGoHome} className="confirmation-home-link">
          Revenir à l'accueil
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPage;
