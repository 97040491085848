import React, { useState } from 'react';
import './CheckoutForm.css';

const CheckoutForm = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [pickup, setPickup] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ email, address: pickup ? 'Pickup' : address });
  };

  return (
    <form className="checkout-form" onSubmit={handleSubmit}>
      <h2>Checkout</h2>
      <div className="form-group">
        <label>Email:</label>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
      </div>
      <div className="form-group pickup-option">
        <input 
          type="checkbox" 
          checked={pickup} 
          onChange={() => setPickup(!pickup)} 
        />
        <label>Pickup from store</label>
      </div>
      {!pickup && (
        <div className="form-group">
          <label>Delivery Address:</label>
          <input 
            type="text" 
            value={address} 
            onChange={(e) => setAddress(e.target.value)} 
            required={!pickup} 
          />
        </div>
      )}
      <button type="submit">Proceed to Payment</button>
    </form>
  );
};

export default CheckoutForm;
